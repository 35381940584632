<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Products</strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Code</th>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Group</th>
                  <th scope="col" class="text-left">Model</th>
                  <th scope="col" class="text-left">Brand</th>
                  <th scope="col" class="text-center">Price</th>
                  <th scope="col" class="text-center">Stock Notify</th>
                  <th scope="col" class="text-center">Available Stock</th>

                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('product_read') ||
                      can('product_update') ||
                      can('product_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="product in products" :key="product.id">
                  <td class="text-left">
                    {{ product.product_code }}
                  </td>
                  <td class="text-left">
                    {{ product.name }}
                  </td>
                  <td class="text-left">{{ product.product_group.name }}</td>
                  <td class="text-left">{{ product.product_model.name }}</td>
                  <td class="text-left">{{ product.brand.name }}</td> 
                  <td class="text-center">{{ product.price }}</td>
                  <td class="text-center">{{ parseInt(product.stock_notify) }}</td>
                  <td :class="`text-center ${parseInt(product.available_stock) <= parseInt(product.stock_notify) ? (parseInt(product.available_stock) == parseInt(product.stock_notify) ? 'text-warning' : 'text-danger') : 'text-success'}`">{{ parseInt(product.available_stock) }}</td>
                  <td
                    class="text-center"
                    v-if="
                      can('product_read') ||
                      can('product_update') ||
                      can('product_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="showProduct(showModal, product)"
                        v-if="can('product_read')"
                      >
                        View</CButton
                      >
                      <CButton
                        color="info"
                        @click="editProduct(product)"
                        v-if="can('product_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteProduct(deleteModal, product)"
                        color="danger"
                        v-if="can('product_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <ProductView />
    <ProductDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const ProductView = () => import("@/views/Product/ProductView");
const ProductDelete = () => import("@/views/Product/ProductDelete");

export default {
  name: "Products",
  components: {
    ProductDelete,
    ProductView
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("products/getProducts");
  },
  computed: {
    ...mapState({
      pagination: (state) => state.products.pagination,
      products: (state) => state.products.products,
      isLoading: (state) => state.products.isLoading,
      deleteModal: (state) => state.products.deleteModal,
      showModal: (state) => state.products.showModal,
    }),
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("products/getProducts", page);
    },
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    showProduct(bool, product) {
      this.$store.commit("products/SHOW_MODAL", {
        bool: bool,
        product: product,
      });
    },
    deleteProduct(bool, product) {
      this.$store.commit("products/DELETE_MODAL", {
        bool: bool,
        product: product,
      });
    },

    editProduct(product) {
      this.$store.dispatch("products/editProduct", {
        product: product,
      });
      this.$router.push({ name: "Edit Product" });
    },
  },
};
</script>
